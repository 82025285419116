menu {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 250px;

  padding: 56px 0px 0px;
  box-sizing: border-box;
  margin: 0px;
  background-color: var(--light-purple);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-buttons-div {
  margin: 24px 0px 8px;
}

.menu-buttons-row {
  display: flex;
  justify-content: center;
}

.sort-button-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sort-button-div button {
  font-size: 0.65rem;
}

.sort-button-div .MuiButton-root {
  color: var(--darker-purple);
  border-color: var(--darker-purple);
}

.sort-button-div p {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--darker-purple);
}

.sort-button-div .tab-button-not-selected {
  color: rgba(25, 118, 210, 0.5);
}

.menu-buttons-div button {
  margin: 0px 6px;
  padding: 6px 8px;
  border-radius: 5px;
  background-color: var(--medium-purple);
}

.menu-buttons-div button svg {
  font-size: 1.2rem;
  color: var(--darker-purple);
}

.menu-buttons-div .not-selected {
  background-color: transparent;
}

.menu-buttons-div .not-selected svg {
  color: var(--main-grey-font);
}

.menu-list {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-list div {
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.menu-list div button {
  text-align: start;
  background-color: transparent;
  color: var(--main-grey-font);
  font-size: 0.95rem;
}

.menu-list div button svg {
  color: #bb0606;
  font-size: 1rem;
}

.menu-list .firm-name {
  font-size: 0.7rem;
  margin-top: 2px;
}

.menu-list .selected {
  background-color: var(--medium-purple);
}

.menu-list .selected button {
  color: var(--darker-purple);
  font-weight: bold;
}

.plus-icon {
  margin: 16px 0px;
  background-color: transparent;
  color: var(--darker-purple);
}

.filters-buttons-div {
  display: flex;
  flex-direction: column;
  width: 90%;
}

.filter-button {
  background-color: #fbfcfe;
  padding: 8px 24px;

  border: 1px solid #dde4f0;
  border-radius: 8px;

  font-size: 0.8rem;
  font-weight: 600;
  color: var(--darker-purple);
  margin-bottom: 8px;
}
