.order-main-div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 480px;
  transform: translate(-50%, -50%);
  background-color: var(--main-grey-background);
  padding: 32px 32px 16px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-main-div h3 {
  padding: 0px;
  margin: 0px;
  font-size: 1.2rem;
}

.order-main-div .form {
  width: 100%;
  margin: 24px 0px 8px;
}

.order-main-div .MuiOutlinedInput-root {
  margin-bottom: 16px;
}

.order-main-div .form .currency-row {
  display: flex;
  justify-content: space-between;
}

.order-main-div .form .currency-row .value {
  flex: 0.75;
}

.order-main-div .form .currency-row .currency {
  flex: 0.2;
}

.order-main-div .form .input-row {
  display: flex;
  justify-content: space-between;
}

.order-main-div .form .input-row .MuiFormControl-root {
  width: 90%;
}

.order-buttons-div {
  display: flex;
}

.order-buttons-div button {
  margin: 0px 8px;
}
