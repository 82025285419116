.filter-main-div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 520px;
  transform: translate(-50%, -50%);
  background-color: var(--main-grey-background);
  padding: 32px 32px 16px;
  border-radius: 10px;
  max-height: 90%;
  box-sizing: border-box;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-main-div h3 {
  padding: 0px;
  margin: 0px;
  font-size: 1.2rem;
}

.filter-main-div .form {
  width: 100%;
  margin: 24px 0px 8px;
}

.filter-row {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.filter-row button {
  margin-left: 8px;
}
