.search-main-div {
  margin: 8px 0px;
}

.search-main-div svg {
  color: var(--darker-purple);
}

.search-main-div .MuiOutlinedInput-input {
  padding: 8px;
  padding-left: 0;
}
