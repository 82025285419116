.account-view-main-div {
  padding: 32px 48px;
}

.account-name-div {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
}

.account-view-main-div h1 {
  font-weight: bold;
  color: #1f384c;
  font-size: 1.3rem;
  margin-right: 8px;
}

.account-view-main-div .text-fields h2 {
  font-weight: bold;
  font-size: 1rem;
  color: var(--darker-purple);
}

.account-view-main-div .text-fields p {
  margin-top: 4px;
  margin-bottom: 16px;
  white-space: pre-line;
}

.account-view-main-div .text-fields .row {
  display: flex;
}

.account-view-main-div .text-fields .row div {
  width: 50%;
}

.account-view-main-div section {
  margin-top: 24px;
}
