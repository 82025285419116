.edit-pair-main-div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 840px;
  transform: translate(-50%, -50%);
  background-color: var(--main-grey-background);
  padding: 32px 32px 32px;
  box-sizing: border-box;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-pair-main-div h3 {
  margin-bottom: 16px;
}

.edit-pair-main-div .buttons-div {
  margin-top: 16px;
}
