.orders-view-main-div {
  padding: 32px 48px;
}

.orders-view-main-div .MuiDataGrid-main {
  height: 72vh;
}

.orders-view-main-div h1 {
  font-weight: bold;
  color: #1f384c;
  font-size: 1.3rem;
}

.orders-view-main-div section {
  margin-top: 24px;
}
