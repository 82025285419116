@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat'),
    url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  src: local('Montserrat'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Montserrat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --darker-purple: #707fdd;
  --medium-purple: rgba(112, 127, 221, 0.1);
  --light-purple: #f1f2f7;

  --main-grey-font: #787f89;
  --main-grey-background: #fafafa;
}

main {
  width: 100%;
  padding-top: 56px;
  padding-left: 240px;
  box-sizing: border-box;
}

button {
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
}

button:hover {
  opacity: 0.6;
  cursor: pointer;
}

button:disabled {
  opacity: 0.2;
  cursor: default;
}

.confirm-icon {
  font-size: 32px !important;
  color: #149d52;
}

.delete-icon {
  font-size: 32px !important;
  color: #bb0606;
}

.MuiDataGrid-main {
  min-height: 400px;
}
