nav {
  position: fixed;
  top: 0;
  height: 56px;
  width: 100%;
  border-bottom: 1px solid #c8cbd9;
  background-color: white;
  padding: 0px 48px;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;
  align-items: center;

  z-index: 100;
}

nav div {
  display: flex;
  align-items: center;
}

nav h1 {
  text-decoration: underline;
  color: #124559;
}

.download-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-icon svg {
  font-size: 2.2rem;
  color: #149d52;
  margin-left: 16px;
}

.backup-button {
  margin-right: 16px;
}

.backup-button svg {
  color: var(--darker-purple);
}
