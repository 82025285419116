.table-title-main-div {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.table-title-main-div h3 {
  font-weight: bold;
  font-size: 1rem;
}

.table-title-main-div button {
  background-color: #fbfcfe;
  height: 100%;
  width: 120px;

  border: 1px solid #dde4f0;
  border-radius: 8px;

  font-size: 0.8rem;
  font-weight: 600;
  color: var(--darker-purple);
}
